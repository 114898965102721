import React, { CSSProperties, PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './history-hero.module.scss';

export function HistoryHero(props: PropsWithChildren<{ id?: string }>) {
    return <div className={styles.historyHero} {...props} />;
}

export function HistoryHeroBackground(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.historyHero__background} {...props} />;
}

export function HistoryHeroBackgroundLayer(props: PropsWithChildren<{}>) {
    return <div className={styles.historyHero__backgroundLayer} {...props} />;
}

export function HistoryHeroBody(props: PropsWithChildren<{}>) {
    return (
        <Grid className={styles.historyHero__body}>
            <div className={styles.historyHero__bodyContainer} {...props} />
        </Grid>
    );
}

export function HistoryHeroImage(props: PropsWithChildren<{}>) {
    return <div className={styles.historyHero__image} {...props} />;
}
