import React from 'react';
import { Helmet } from 'react-helmet';
import { useMedia } from 'react-use';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useLax } from 'utils/hooks/lax';
import {
    HistoryHero,
    HistoryHeroBackground,
    HistoryHeroBackgroundLayer,
    HistoryHeroBody,
} from 'components/history-hero/history-hero';
import {
    Block,
    Block2,
    BlockBackground,
    BlockBackgroundLayer,
    BlockBody,
    Step,
    StepBody,
    StepHeading,
    StepImage,
    StepLabel,
    Steps,
    StepsBackground,
    StepsBackgroundLayer,
    StepsBody,
    StepsList,
    StepText,
} from 'components/steps/steps';

export default function HistoryPage(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    const isLg = useMedia('(min-width: 1300px)', false);
    useLax();

    return (
        <>
            <Helmet>
                <title>Phase One Karma — Our Story.</title>
                <meta name="description" content="How we got here — the story behind Phase One Karma." />
                <meta property="og:title" content="Phase One Karma — Our Story." />
                <meta property="og:description" content="How we got here — the story behind Phase One Karma." />
            </Helmet>

            <HistoryHero id="history-hero">
                <HistoryHeroBody>
                    <span>There is a story to how phase one karma got where it is</span>
                    <p>
                        Starting as a team of four, Phase One Karma initially was just a small team with a great idea.
                        At that point, in the early 2010s, we did not think much beyond Unicheck, our firstborn.
                    </p>
                </HistoryHeroBody>
                <HistoryHeroBackground>
                    <HistoryHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 300">
                        <Img fluid={data.heroClouds.childImageSharp.fluid} />
                    </HistoryHeroBackgroundLayer>
                    <HistoryHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 200">
                        <Img fluid={data.heroMountain.childImageSharp.fluid} />
                    </HistoryHeroBackgroundLayer>
                    <HistoryHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 100">
                        <Img fluid={data.heroForest.childImageSharp.fluid} />
                    </HistoryHeroBackgroundLayer>
                    <HistoryHeroBackgroundLayer data-lax data-lax-translate-y="0 0, vh 50">
                        <Img fluid={data.heroPeople.childImageSharp.fluid} />
                    </HistoryHeroBackgroundLayer>
                    <HistoryHeroBackgroundLayer data-lax data-lax-translate-x="0 100, vh -100">
                        <Img fluid={data.heroBirds.childImageSharp.fluid} />
                    </HistoryHeroBackgroundLayer>
                    <HistoryHeroBackgroundLayer
                        data-lax
                        data-lax-translate-x="0 0, vh 100"
                        data-lax-translate-y="0 0, vh -100"
                    >
                        <Img fluid={data.heroDrone.childImageSharp.fluid} />
                    </HistoryHeroBackgroundLayer>
                </HistoryHeroBackground>
            </HistoryHero>

            <Steps>
                <StepsBackground>
                    <StepsBackgroundLayer
                        color="haiti"
                        style={{ height: isLg ? 1350 : 1100, width: '100%' }}
                        id="dark-bg"
                    >
                        <Img fluid={data.divider1.childImageSharp.fluid} style={{ transform: 'translateY(-56%)' }} />
                    </StepsBackgroundLayer>

                    <StepsBackgroundLayer color="magnolia" style={{ height: 500, width: '100%' }} id="light-bg">
                        <Img fluid={data.divider2.childImageSharp.fluid} style={{ transform: 'translateY(-99%)' }} />
                    </StepsBackgroundLayer>
                    <StepsBackgroundLayer
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundImage: `url(${require('../img/circles.svg')}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '-105px 1500px',
                            backgroundSize: '2428px 2439px',
                        }}
                    />
                    <StepsBackgroundLayer
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundImage: `url(${require('../img/circles.svg')}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '-1000px 4200px',
                            backgroundSize: '2428px 2439px',
                        }}
                    />
                </StepsBackground>

                <StepsBody>
                    <StepsList>
                        <Step color="dark" style={{ marginBottom: 160 }}>
                            <StepBody>
                                <StepLabel>2014</StepLabel>
                                <StepText>
                                    And we did not need to. We knew some things for sure: universities had an increasing
                                    issue of plagiarism and there were available technologies that could help. We dug
                                    into the problem, gathered more people around (the image below is our first ever
                                    team photo) and came up with a solution.
                                </StepText>
                            </StepBody>
                        </Step>
                        <Step color="light" style={{ marginBottom: 200 }}>
                            <StepBody>
                                <StepImage size="big">
                                    <Img fluid={data.history1.childImageSharp.fluid} loading="lazy" />
                                </StepImage>
                                <StepLabel>2014</StepLabel>
                                <StepText>
                                    In 2014, we released Unicheck (initially named Unplag), P1K’s first AI-powered
                                    product aimed to put an end to academic dishonesty by using natural language
                                    understanding technology. Rapidly becoming a success story, we opened an in-house
                                    R&D department the same year and grew to a team of 43. Keeping the bar of
                                    expectations high, P1K set the goal of championing the industry.
                                </StepText>
                            </StepBody>
                        </Step>
                        <Step color="light">
                            <StepBody>
                                <StepImage size="big">
                                    <Img fluid={data.history2.childImageSharp.fluid} loading="lazy" />
                                </StepImage>
                                <StepLabel>2017</StepLabel>
                                <StepText>
                                    Flashforward to 2017. Unicheck has got itself a fresh name and a new feature called
                                    Emma — a built-in authorship verification technology. Becoming an EdTech success
                                    story with more than 1 million users across 90 countries, by 2020 when we sold
                                    Unicheck, it had a self-sustainable team of 80.
                                </StepText>
                            </StepBody>
                        </Step>
                        <Block style={{ marginBottom: 0 }}>
                            <BlockBody>
                                <span style={{ maxWidth: 592 }}>
                                    That's where it hit us — we should not stop with just Unicheck.
                                </span>
                                <BlockBackground>
                                    <BlockBackgroundLayer
                                        data-lax
                                        data-lax-anchor="self"
                                        data-lax-translate-y="vh 0, -1000 100"
                                    >
                                        <Img fluid={data.clouds1.childImageSharp.fluid} loading="lazy" />
                                    </BlockBackgroundLayer>

                                    <BlockBackgroundLayer
                                        data-lax
                                        data-lax-anchor="self"
                                        data-lax-translate-y="vh 0, -1000 200"
                                    >
                                        <Img fluid={data.clouds2.childImageSharp.fluid} loading="lazy" />
                                    </BlockBackgroundLayer>
                                    <BlockBackgroundLayer
                                        data-lax
                                        data-lax-anchor="self"
                                        data-lax-translate-y="vh 0, -1000 -200"
                                        data-lax-translate-x="vh 100, -1000 200"
                                        // style={{ maxWidth: 610 }}
                                    >
                                        <Img
                                            fluid={data.birds.childImageSharp.fluid}
                                            style={{ maxWidth: 610 }}
                                            loading="lazy"
                                        />
                                    </BlockBackgroundLayer>
                                </BlockBackground>
                            </BlockBody>
                        </Block>
                        <Step color="light" style={{ marginBottom: 240 }}>
                            <StepBody>
                                <StepLabel>2019</StepLabel>
                                <StepHeading>the turning point</StepHeading>
                                <StepText>
                                    We had great ideas, we realized the responsibility that AI technology carried, and
                                    we already had enough experience to take on multiple projects. That was the turning
                                    point for Phase One Karma, a moment when "four guys in a garage with a dream" type
                                    of story transformed into a "bigger than just a product company" story.
                                </StepText>
                                <StepText>
                                    In 2019, we decided to turn our attention to other industries. That’s when we came
                                    up with Loio — a powerful AI innovation meant to simplify lawyers’ lives by
                                    optimizing their paperwork routine. By that point, Phase One Karma already had its
                                    flourishing community, vibrant inner culture, and fully realized far-reaching
                                    ambitions.
                                </StepText>
                            </StepBody>
                        </Step>
                        <Step color="light">
                            <StepBody>
                                <StepImage size="big">
                                    <Img fluid={data.history3.childImageSharp.fluid} loading="lazy" />
                                </StepImage>
                                <StepHeading>more to come</StepHeading>
                                <StepText>
                                    We may not be sure what our products will be like in 5 or 10 years, but we have a
                                    strategy that will make sure these products are going to be awesome. We are building
                                    a fruitful community of engineers, managers, and creatives that loves its projects
                                    and exchanges ideas on a daily basis. We also have a clear philosophy behind our
                                    work, which revolves around the meaningfulness of each product and its impact on the
                                    quality of people's lives. And, of course, we've got a lot of ambitions that keep
                                    pushing us forward each day.
                                </StepText>
                            </StepBody>
                        </Step>
                        <Block2 style={{ marginBottom: 0 }}>
                            <span style={{ maxWidth: 800 }}>
                                We are building a fruitful community of engineers, managers, and creatives that loves
                                its projects and exchanges ideas on a daily basis.
                            </span>
                        </Block2>
                    </StepsList>
                </StepsBody>
            </Steps>
        </>
    );
}

export const imgQuery = graphql`
    query {
        heroClouds: file(relativePath: { eq: "history/hero/clouds.png" }) {
            childImageSharp {
                fluid(maxWidth: 2382, srcSetBreakpoints: [2382], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroMountain: file(relativePath: { eq: "history/hero/mountain.png" }) {
            childImageSharp {
                fluid(maxWidth: 2382, srcSetBreakpoints: [768, 2382], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        heroForest: file(relativePath: { eq: "history/hero/forest.png" }) {
            childImageSharp {
                fluid(maxWidth: 2382, srcSetBreakpoints: [768, 2382], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroPeople: file(relativePath: { eq: "history/hero/people.png" }) {
            childImageSharp {
                fluid(maxWidth: 2382, srcSetBreakpoints: [768, 2382], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroBirds: file(relativePath: { eq: "history/hero/birds.png" }) {
            childImageSharp {
                fluid(maxWidth: 2382, srcSetBreakpoints: [768, 2382], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        heroDrone: file(relativePath: { eq: "history/hero/drone.png" }) {
            childImageSharp {
                fluid(maxWidth: 2382, srcSetBreakpoints: [768, 2382], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        divider1: file(relativePath: { eq: "history/div-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [768, 2732], quality: 95) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        divider2: file(relativePath: { eq: "history/div-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [768, 2732], quality: 95) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        history1: file(relativePath: { eq: "history/history-1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1008, srcSetBreakpoints: [1008], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        history2: file(relativePath: { eq: "history/history-5.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1008, srcSetBreakpoints: [1008], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        history3: file(relativePath: { eq: "history/history-3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1008, srcSetBreakpoints: [1008], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        clouds1: file(relativePath: { eq: "history/clouds-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [2732], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        clouds2: file(relativePath: { eq: "history/clouds-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [2732], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        birds: file(relativePath: { eq: "history/birds.png" }) {
            childImageSharp {
                fluid(maxWidth: 610, srcSetBreakpoints: [610], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
