import React, { CSSProperties, PropsWithChildren, useRef } from 'react';
import cn from 'classnames';

import { getOffsetTop } from 'utils/get-offset-top';

import { Grid } from 'components/grid/grid';

import styles from './steps.module.scss';

export function Steps(props: PropsWithChildren<{}>): JSX.Element {
    return <Grid className={styles.steps} {...props} />;
}

export function StepsBackground(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.steps__background} {...props} />;
}

export function StepsBackgroundLayer(
    props: PropsWithChildren<{ color?: 'haiti' | 'magnolia'; style?: CSSProperties; id?: string }>,
): JSX.Element {
    const { color, ...restProps } = props;

    return (
        <div
            className={cn(styles.steps__backgroundLayer, {
                [styles.steps__backgroundLayerHaiti]: color === 'haiti',
                [styles.steps__backgroundLayerMagnolia]: color === 'magnolia',
            })}
            {...restProps}
        />
    );
}

export function StepsBody(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.steps__body} {...props} />;
}

export function StepsAside(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.steps__aside} {...props} />;
}

export function StepsList(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.steps__list} {...props} />;
}

export function Stepper(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.stepper} {...props} />;
}

export function StepperItem(
    props: PropsWithChildren<{ active?: boolean; anchorId: string; color: 'light' | 'dark' }>,
): JSX.Element {
    const { active, anchorId, color, children, ...restProps } = props;

    const ref = useRef<HTMLAnchorElement>(null);

    return (
        <a
            ref={ref}
            href={`#${anchorId}`}
            data-stepper-anchor-id={anchorId}
            onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                window.history.pushState({}, '', `#${anchorId}`);

                const { current: buttonEl } = ref;
                const targetEl = document.getElementById(anchorId);
                if (buttonEl !== null && targetEl !== null) {
                    const { top } = buttonEl.getBoundingClientRect();
                    window.scroll({ top: getOffsetTop(targetEl) - top + 7, behavior: 'smooth' });
                }
            }}
            className={cn(styles.stepper__item, {
                [styles.stepper__itemActive]: active,
                [styles.stepper__itemDark]: color === 'dark',
                [styles.stepper__itemLight]: color === 'light',
            })}
            {...restProps}
        >
            {children}
        </a>
    );
}

export function Block(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.block} {...props} />;
}

export function Block2(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.block2} {...props} />;
}

export function BlockBackground(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.block__background} {...props} />;
}

export function BlockBackgroundLayer(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.block__backgroundLayer} {...props} />;
}

export function BlockBody(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.block__body} {...props} />;
}

export function Step(props: PropsWithChildren<{ color: 'light' | 'dark'; style?: CSSProperties }>): JSX.Element {
    const { color, ...restProps } = props;
    return (
        <div
            className={cn(styles.step, {
                [styles.stepDark]: color === 'dark',
                [styles.stepLight]: color === 'light',
            })}
            {...restProps}
        />
    );
}

export function StepBody(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.step__body} {...props} />;
}

export function StepImage(props: PropsWithChildren<{ size?: 'big' }>): JSX.Element {
    const { size, ...restProps } = props;

    return (
        <div
            className={cn(styles.step__image, {
                [styles.step__imageBig]: size === 'big',
            })}
            {...restProps}
        />
    );
}

export function StepLabel(props: PropsWithChildren<{ withPadding?: boolean; id?: string }>): JSX.Element {
    const { withPadding, ...restProps } = props;
    return <div className={cn(styles.step__label, { [styles.step__labelWithPadding]: withPadding })} {...restProps} />;
}

export function StepQuote(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <span className={styles.step__quote} {...props} />;
}

export function StepHeading(props: PropsWithChildren<{}>): JSX.Element {
    return <span className={styles.step__heading} {...props} />;
}

export function StepText(props: PropsWithChildren<{}>): JSX.Element {
    return <p className={styles.step__text} {...props} />;
}
